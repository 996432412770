<template>
  <section class="flex-grow">
    <base-card>
      <h2>Page not found</h2>
      <p>
        This page could not be found - maybe check out the
        <router-link to="/blog">post archive</router-link>.
      </p>
    </base-card>
  </section>
</template>
